'use strict';

// const logger = new Logger('[push-simple/app]');
//

export function setup(onSubscribed) {
    //  console.log('Setting up push subscription');

    if (!window.PushManager) {
        console.log('Push messaging is not supported in your browser');
    }

    if (!ServiceWorkerRegistration.prototype.showNotification) {
        console.log('Notifications are not supported in your browser');
        return;
    }

    if (Notification.permission !== 'granted') {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                console.log('Permission to receive notifications granted!');
                subscribe(onSubscribed);
            }
        });
        return;
    } else {
        console.log('Permission to receive notifications granted!');
        subscribe(onSubscribed);
    }
}

function subscribe(onSubscribed) {
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        var pushManager = serviceWorkerRegistration.pushManager;
        pushManager.getSubscription().then(function (subscription) {
            if (subscription) {
                //refreshSubscription(pushManager, subscription, onSubscribed);
            } else {
                pushManagerSubscribe(pushManager, onSubscribed);
            }
        });
    });
}

function refreshSubscription(pushManager, subscription, onSubscribed) {
    console.log('Refreshing subscription');
    return subscription.unsubscribe().then(function (bool) {
        pushManagerSubscribe(pushManager);
    });
}


function pushManagerSubscribe(pushManager, onSubscribed) {
    console.log('Subscribing started...');

    pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
    }).then(onSubscribed).then(function () {
        console.log('Subcribing finished: success!');

        getSubscription().then(function (subscription) {
            fetch("/webpush/subscribe", {
                headers: formHeaders(),
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({subscription: subscription.toJSON()})
            });
        });


    }).catch(function (e) {
        if (Notification.permission === 'denied') {
            console.log('Permission to send notifications denied');
        } else {
            console.error('Unable to subscribe to push', e);
        }
    });
}

export function logSubscription(subscription) {
    console.log("Current subscription", subscription.toJSON());
}

function getSubscription() {
    return navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        return serviceWorkerRegistration.pushManager.getSubscription().catch(function (error) {
            console.log('Error during getSubscription()');
        });
    });
}


function formHeaders() {
    return new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticityToken()
    });
}

function authenticityToken() {
    return document.querySelector('meta[name=csrf-token]').content;
}


