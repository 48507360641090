import 'bootstrap-datepicker';

export function submitReportDate(){
  var report_date = $("#report_date_picker").val()

  if(!!report_date && (moment(report_date, 'MM/DD/YYYY', true).isValid())) {
    $("#hidden_report_date").val(report_date)
    addLoaderScreen()
    $("#managers_daily_report_form").submit()
  }
}

$(document).ready(function(){
  var current_day = $("#report_date_picker").data("current_date");

  $('#report_date_picker').datepicker({
    changeMonth: true,
    changeYear: true,
    endDate: new Date(current_day),
    orientation: "bottom auto"
  });

  $('#yearly_report_datepicker').datepicker({
    format: "yyyy",
    viewMode: "years",
    minViewMode: "years",
    orientation: 'left bottom',
    endDate: new Date()
  });
})