// console.log("Hello from app/javascript/packs/application.js!");

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'jquery-ui';
import "channels";
import "@hotwired/turbo-rails";
import 'jquery-ui/ui/widgets/dialog';

import bootbox from 'bootbox';
window.bootbox = bootbox ;

import Highcharts from 'highcharts';
window.Highcharts = Highcharts;

import Rails from "@rails/ujs";
Rails.start();

// Import jQuery UI DatePicker
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css'; 
import 'bootstrap';

import qz from 'qz-tray';
window.qz = qz;

import visitMetricFunctions from 'visit_metrics';
Object.keys(visitMetricFunctions).forEach(function(key) {
    window[key] = visitMetricFunctions[key];
});

// import 'bootstrap/dist/js/bootstrap.bundle';
import 'select2';
import './autocomplete';

import 'eonasdan-bootstrap-datetimepicker';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

// Import DataTables
import 'datatables.net';

// Import FullCalendar
import moment from 'moment';
window.moment = moment;
// console.log(moment().format('YYYY-MM-DD')); // Example usage
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'fullcalendar/dist/fullcalendar.css';
// console.log("Full calendar imported!");

//Import cocoon
require("jquery")
require("@nathanvda/cocoon")

// Import SignaturePad
import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;


// Import everything else
const importAll = (r) => r.keys().forEach(r);
importAll(require.context('../', true, /^(?!.*packs).*\.js$/));

import dymo from '../dymo.2.0.2';
window.dymo = dymo;

import applicationFunctions from 'application';
Object.keys(applicationFunctions).forEach(function(key) {
    window[key] = applicationFunctions[key];
});

import {attach_camera, take_snapshot} from 'cam';
window.attach_camera = attach_camera;
window.take_snapshot = take_snapshot;

import {sendTemplateFile} from "summernote_upload";
window.sendTemplateFile = sendTemplateFile;

import {initFileUpload, submitValidatedForm, isNewForm, addHidden} from 'auto_form_submit';
window.initFileUpload = initFileUpload;
window.submitValidatedForm = submitValidatedForm;
window.isNewForm = isNewForm;
window.addHidden = addHidden;

import {submitReportDate} from 'report_date_picker';
window.submitReportDate = submitReportDate;

import roomsFunctions from 'rooms';
Object.keys(roomsFunctions).forEach(function(key) {
    window[key] = roomsFunctions[key];
});

import {initNewDrag} from 'rooms_dragging.js.erb';
window.initNewDrag = initNewDrag;

import signatureConsole from 'signature_console';
Object.keys(signatureConsole).forEach(function(key) {
    window[key] = signatureConsole[key];
});

import squareFunctions from 'square';
import squareWebPayment from 'square_web_payment';
import { createConsumer } from '@rails/actioncable';
window.squareWebPayment = squareWebPayment;
Object.keys(squareFunctions).forEach(function(key) {
    window[key] = squareFunctions[key];
});

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// $(document).on("turbo:load", () => {
//     console.log("turbo!");
//   });

// console.log("All libraries imported!");

// Example usage of DYMO SDK
// document.addEventListener('DOMContentLoaded', () => {
//     if (window.dymo) {
//       console.log('DYMO SDK loaded successfully');
//       // Add your DYMO SDK initialization and usage code here
//     } else {
//       console.error('Failed to load DYMO SDK');
//     }
//   });


// document.addEventListener('DOMContentLoaded', () => {
//     // Example: Connect to QZ Tray
//     qz.websocket.connect().then(() => {
//       console.log('Connected to QZ Tray');
//     }).catch(err => {
//       console.error('Failed to connect to QZ Tray', err);
//     });
  
//     // Example: Print a simple message
//     const printData = [{
//       type: 'raw',
//       format: 'plain',
//       data: 'Hello, QZ Tray!'
//     }];
  
//     qz.print(config, printData).then(() => {
//       console.log('Print successful');
//     }).catch(err => {
//       console.error('Print failed', err);
//     });
//   });