import consumer from "./consumer"

consumer.subscriptions.create({channel: "PushesChannel"}, {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log("pushes connected!!")
  },

  disconnected() {
    // console.log("pushes disconnected!!")
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("Received data.")
    if (!$('div.bootbox').length) {
      bootbox.alert({ message: data["message"] });
    }
  }
});