export function sendTemplateFile(file, toSummernote) {
  var data = new FormData();
  data.append('logo', file);
  $.ajax({
    data: data,
    type: 'POST',
    url: "/locations/" + toSummernote.data('location') + "/save_email_template_logo?template=" + toSummernote.data('template'),
    cache: false,
    contentType: false,
    processData: false,
    success: function(data) {
      // console.log 'file uploading...'
      toSummernote.summernote("insertImage", data.url);
    }
  });
}

$(document).ready(function(event) {
  $('.summernote_template_editor').each(function() {
    $(this).summernote({
      callbacks: {
        onImageUpload: function(files) {
          sendTemplateFile(files[0], $(this));
        }
      }
    });
  });
});