var download_csv_table;

$(document).ready(function(){
  $("#report_starts_date").datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true,
    endDate: "0d"
  }).on('changeDate', function (selected) {
    // var startDate = new Date(selected.date.valueOf());
    // $('#report_ends_date').datepicker('setStartDate', startDate);
  })

  $("#report_ends_date").datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true,
    endDate: "0d"
  }).on('changeDate', function (selected) {
    // var endDate = new Date(selected.date.valueOf());
    // $('#report_starts_date').datepicker('setEndDate', endDate);
  })

  var startDate = new Date($('#report_starts_date').val());
  // $('#report_ends_date').datepicker('setStartDate', startDate);

  $(".solo_visit_notes").each(function(){
    toggleNotes($(this).data("family"))
  })

  initVisitMetricsDataTable()

  // DOWNLOAD_AS_CSV
  $(".download_csv_trigger").on('click', function (event) {
    var csv_table = $('.download_csv_table')
    var csv_name = csv_table.data("csv-name") || "Report"
    download_csv_table.destroy()
    exportTableToCSV.apply(this, [csv_table, csv_name+'.csv'])
    initVisitMetricsDataTable()
  });
})

 function toggleNotes(family_id){
  var notes = $(".notes_"+family_id)
  var read_span = $(".notes_read_span_"+family_id)
  read_span.empty()
  if (notes.height() > 49){
  if(!notes.hasClass("partial_notes")){
    notes.addClass("partial_notes")
    notes.css({'height':'50px', 'overflow-y':'hidden'})
    read_span.append("<a href='javascript:void(0)' onclick='toggleNotes("+family_id+")'>Read More...</a>")
  } else {
    notes.removeClass("partial_notes")
    notes.css({'height':'100%'})
    read_span.append("<a href='javascript:void(0)' onclick='toggleNotes("+family_id+")'>Read Less...</a>")
  }
  }
}

 function initVisitMetricsDataTable(){
  if($(".download_csv_table").length){
    var indexLastColumn = $(".download_csv_table").find('tr')[0].cells.length-2;
    download_csv_table = $('.download_csv_table').DataTable({
      "order":[[indexLastColumn, "asc" ]]
    })
  }
}

 function submitVisitMetricForm(obj){
  $(obj)[0].form.submit()
  addLoaderScreen()
}

 function closeFamilyNotesModal(){
  $('#family_notes_modal form').attr('action', '#')
  $('#family_notes_modal textarea').val('')
  $('#family_notes_modal').modal('hide')
}

 function enableNotesSubmition(obj){
  if(!!$(obj).val()) {
    $('#family_notes_modal input[type="submit"]').removeAttr("disabled")
  } else {
    $('#family_notes_modal input[type="submit"]').attr("disabled", "disabled")
  }
}

const visitMetricFunctions = {
  enableNotesSubmition,
  closeFamilyNotesModal,
  submitVisitMetricForm,
  initVisitMetricsDataTable,
  toggleNotes
};

export default visitMetricFunctions;