// this will submit the form after file change
export function initFileUpload() {
  $('[type="file"]').change(function(){
    submitValidatedForm(this.form)
  });
  $('.trigger_file_upload').change(function(){
    submitValidatedForm(this.form)
  });
  $('.trigger_date_upload').change(function(){
    var newDate = $(this).val();
    if (!moment(newDate, 'MM/DD/YYYY', true).isValid()){
      $(this).val("");
      alert('Please enter a valid date');
    }
    else{
    submitValidatedForm(this.form)
    }
  });
  $("a:contains('Remove')").not('.remove_cocoon').click(function(){
    if(confirm('Are you sure?')){
      submitValidatedForm($(this).closest("form")[0])
    } else {
      event.preventDefault()
      return false
    }
  });
}

export function submitValidatedForm(current_form) {
  if(!current_form || isNewForm(current_form)){ return false}
  if(current_form.checkValidity()){
    addLoaderScreen("uploading")
    addHidden(current_form)
    current_form.submit()
  } else {
    removeLoaderScreen()
    current_form.reportValidity()
  }
}

export function isNewForm(current_form){
  // console.log(current_form)
  return (current_form.id.includes("new") || current_form.className.includes("new"))
}

export function addHidden(theForm) {
  var input = document.createElement('input');
  input.type = 'hidden';
  input.name = "auto_file_upload";
  input.value = "true";
  theForm.appendChild(input);
}

$(document).ready(function () {
  // preventPageLeave()
  initFileUpload()
  $("#kids_form").on("cocoon:after-remove", function (event, insertedItem) {
    if (insertedItem && insertedItem.prevObject && insertedItem.prevObject.hasClass('existing')) {
      submitValidatedForm($(this).closest("form")[0])
    }
  })
  $("#kids_form").on("cocoon:before-remove", function(event, insertedItem) {
    if (insertedItem && insertedItem.prevObject && insertedItem.prevObject.hasClass('existing')) {
      if(!confirm('Are you sure?')){
        event.preventDefault()
      }
    }
  })

  $("#parents_form").on("cocoon:after-remove", function (event, insertedItem) {
    if (insertedItem && insertedItem.prevObject && insertedItem.prevObject.hasClass('existing')) {
      submitValidatedForm($(this).closest("form")[0])
    }
  })
  $("#parents_form").on("cocoon:before-remove", function(event, insertedItem) {
    if (insertedItem && insertedItem.prevObject && insertedItem.prevObject.hasClass('existing')) {
      if(!confirm('Are you sure?')){
        event.preventDefault()
      }
    }
  })
});

$(document).on("cocoon:after-insert", function () {
  initFileUpload()
});

// this will alert when page redirects without submition of uploaded files
// function preventPageLeave() {
//   $("select, textarea, [type='text'], [type='number'], [type='checkbox'], [type='radio']").on("change", function(){
//     let current_form = $(this).closest("form")[0]
//     if(current_form && !isNewForm(current_form)){
//       $(window).bind('beforeunload', function(){
//         return 'You have not saved your changes. Are you sure you want to leave the page ?';
//       });
//     }
//   });
//   $("[type='submit']").on("click", function(){
//     $(window).unbind('beforeunload');
//   });
// }
