// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require jquery
//= require moment/moment
//= require dataTables/jquery.dataTables
//= require cocoon
//= require moment 
//= require fullcalendar
//= require jquery-ui
//= require bootstrap-datetimepicker
//= require bootstrap-datepicker
//= require jquery_readyselector
//= require jquery-ui.multidatespicker
//= require webcam.min
//= require highcharts
//= require fSelect
//= require signature_pad

//= require_tree .




function print_page(){
  $('.print_page_header').removeClass('hide');
  window.print();
  $('.print_page_header').addClass('hide');
}


$(document).ready(function () {
  // select2 Theme //
  insert_select2_class();
  $(".blank_dropdown").each(function()
  {
    $(this).append("<option></option>");
  })
  $('.select2').select2();
  $('.redeem_flexpass').select2('destroy');

  //sidebar-toggle on md to lg screens
  $("#sidebar-toggle-view").click(function(e) {
    if($("#sidebar").hasClass("sidebar_open")){
      $('.custom_theme_sidebar').animate({"width" : "70px"});
      $('.text-toggle').css({"display" : "none"});
      $('.side-nav li i[class*=fa]' ).css({"width" : "none", "margin-right" : "0", "float" : "none"});
      $("#sidebar").removeClass("sidebar_open");
      $('.custom_theme_content').animate({"padding-left": "100"},"veryfast");
    } else {
      $("#sidebar").addClass("sidebar_open");
      $('.custom_theme_sidebar').animate({"width" : "220px"});
      $('.text-toggle').css({"display" : "block"});
      $('.side-nav li i[class*=fa]' ).css({"width" : "16px", "margin-right" : "8px", "float" : "left"});
      $('.custom_theme_content').animate({"padding-left": "237px"},"veryfast");
    }
  });
});


function insert_select2_class() {
  $('select').each(function(){
    if (!$(this).hasClass('do_not_initialize_select2')) {
      $(this).removeClass('input-transparent ,select');
      $(this).addClass('select2');
    }
  });
}

function exportTableToCSV($table, filename) {
  var $rows = $table.find('tr:has(td),tr:has(th)'),
  // Temporary delimiter characters unlikely to be typed by keyboard
  // This is to avoid accidentally splitting the actual contents
  tmpColDelim = String.fromCharCode(11), // vertical tab character
  tmpRowDelim = String.fromCharCode(0), // null character
  // actual delimiter characters for CSV format
  colDelim = '","',
  rowDelim = '"\r\n"',
  // Grab text from table into CSV formatted string
  csv = '"' + $rows.map(function (i, row) {
    var $row = $(row), $cols = $row.find('td,th').not('.dont_print');
    return $cols.map(function (j, col) {
      var $col = $(col), text = $col.text()
      return  text.trim();
      // return text.replace(/\t/g, "").replace(/\n/g, "").replace(/"/g, '""'); // escape double quotes
    }).get().join(tmpColDelim);
  }).get().join(tmpRowDelim)
  .split(tmpRowDelim).join(rowDelim)
  .split(tmpColDelim).join(colDelim) + '"',
  // Data URI
  csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv);
  // console.log(csv);
  // console.log(window.navigator.msSaveBlob);
  if (window.navigator.msSaveBlob) 
  { // IE 10+
    //alert('IE' + csv);
    window.navigator.msSaveOrOpenBlob(new Blob([csv], {type: "text/plain;charset=utf-8;"}), "csvname.csv")
  }
  else 
  {
    $(this).attr({ 'download': filename, 'href': csvData, 'target': '_blank' });
  }
}

function exportTableToXlsx($table, filename) {
  var $rows = $table.find('tr:has(td),tr:has(th)'),
  tmpColDelim = String.fromCharCode(11), 
  tmpRowDelim = String.fromCharCode(0), 
  colDelim = '","',
  rowDelim = '"\r\n"',
  xlsx = '"' + $rows.map(function (i, row) {
    var $row = $(row), $cols = $row.find('td,th');
    return $cols.map(function (j, col) {
      var $col = $(col), text = $col.text();
      return text.replace(/\t/g, "").replace(/\n/g, "").replace(/"/g, '""'); // escape double quotes
    }).get().join(tmpColDelim);
  }).get().join(tmpRowDelim)
  .split(tmpRowDelim).join(rowDelim)
  .split(tmpColDelim).join(colDelim) + '"',
  xlsxData = 'data:application/xlsx;charset=utf-8,' + encodeURIComponent(xlsx);
  console.log(xlsx);
  console.log(window.navigator.msSaveBlob);
  if (window.navigator.msSaveBlob) 
  { 
    window.navigator.msSaveOrOpenBlob(new Blob([xlsx], {type: "text/plain;charset=utf-8;"}), "xlsxname.xlsx")
  }
  else 
  {
    $(this).attr({ 'download': filename, 'href': xlsxData, 'target': '_blank' });
  }
}

function visibilty_plans(obj) {
  if($(obj).filter(':checked').val() == 'true'){
    $(".plans_field").show();
  }else{
    $(".plans_field").hide();
  }
}
function change_flexpass_redeemption(camp_activity_id, child_visit_id,child_visit_pack_id,promo_package_id) {
  $.ajax({
    url: '/checkout/change_flexpass_redeemption',
    type: 'post',
    data: {camp_activity_id: camp_activity_id, child_visit_id: child_visit_id,child_visit_pack_id: child_visit_pack_id,promo_package_id: promo_package_id}
  });
}
function loadCampsForTheDay(kid_id, value)
{
  $.ajax({
    url: '/pass_redemption/camps',
    type: 'get',
    data: {kid_id: kid_id, promo_package_id: $(value).val()}
  });
}

function set_redeem_toggle(kid_id,value){
  var promo_package_id = $(`#purchase_dropdown_id_${kid_id} option:selected`).val();
  if (value.checked == true){
    loadCampsForTheDay(kid_id,$(`#purchase_dropdown_id_${kid_id} option:selected`))
  }
  if (value.checked == false){
    $("#redeem_pass_for_camp_"+kid_id).html('');
    $(".flexpass_redeemption_"+kid_id).remove();
  }
}

const applicationFunctions = {
  insert_select2_class,
  exportTableToCSV,
  exportTableToXlsx,
  visibilty_plans,
  change_flexpass_redeemption,
  loadCampsForTheDay,
  set_redeem_toggle,
}

export default applicationFunctions;