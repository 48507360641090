async function squareWebPayment(application_id, location_id) {
  const payments = Square.payments(application_id, location_id);
  const card = await payments.card();
  await card.attach('#card-container');

  async function eventHandler(event) {
    event.preventDefault();

    try {
      const result = await card.tokenize();
      if (result.status === 'OK') {
        // console.log(`Payment token is ${result.token}`);
        $("#card-errors").hide();
        $("#card-errors > .err_lists").empty();
        $('.saved_card_lits').each(function(){$(this).removeAttr('checked')});
        $('#nonce_field').val(result.token);
        $('#payment-form').submit();
      }
    } catch (errors) {
      console.error(e);
    }
  };

  const cardButton = document.getElementById('new_square_card_btn');
  cardButton.addEventListener('click', eventHandler);
}

export default squareWebPayment;