import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "month" , "year", "reportYearType"]
  connect() {
    this.showHidePicker();
  }
  showHidePicker()
  {
   if(this.reportYearTypeTarget.checked)
   {
     this.monthTarget.style.display = "none";
     this.monthTarget.disabled = true;
     this.yearTarget.style.display = "block";
     this.yearTarget.disabled = false;
   }
   else
   {
     this.monthTarget.style.display = "block";
     this.monthTarget.disabled = false;
     this.yearTarget.style.display = "none";
     this.yearTarget.disabled = true;
   }
  }

  validateInput(event)
  {
    //console.log("validating input");
    let date_format = "YYYY";
    let date = this.yearTarget.value;

    if(this.reportYearTypeTarget.checked)
    {
      if(this.yearTarget.value === "")
      {
        alert("Please enter year");
        event.preventDefault();
        return false;
      }
    }
    else
    {
      if(this.monthTarget.value === "")
        {
          alert("Please enter month");
          event.preventDefault();
          return false;
        }
        date = this.monthTarget.value;
        date_format = "MM-YYYY";
    }

    if (!moment(date, date_format, true).isValid()){
      //date is invalid
      alert("Please enter a valid date in "+date_format+" format");
      event.preventDefault();
    }
    
  }
}
