$(document).ready(function(){
  initNewDrag();
})
 export function initNewDrag (){ 
  $(".primary_board .card").click(function(){
    const UNCLASSIFIED_CHILDREN_KEY = "unclassified_children";
    var room_id, kid_id
    var parentDiv = $(this).parent()
    if(parentDiv.hasClass(UNCLASSIFIED_CHILDREN_KEY)){
      room_id = UNCLASSIFIED_CHILDREN_KEY
    } else {
      room_id = parentDiv.data("room")
    }
    kid_id = $(this).data("kid-id")
    $.get("/rooms/dragging_board", {room_id: room_id, kid_id: kid_id})
  })
}

  

  