import consumer from "./consumer"

consumer.subscriptions.create({channel: "ParentSignaturePopupChannel"}, {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    $('#parent_signature_popup_div').empty().append(data['html']);
    $('#parent_signature_popup').modal('show');
  }
});