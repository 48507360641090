function show_edit_form(){
  $(".right_box").hide();
  $(".left_box").removeClass("col-md-4");
  $(".left_box").addClass("col-md-12");
  $(".left_box .details_component").hide();
  $(".left_box .edit_component").show();
}
function cancel_edit_form(){
  $(".left_box .edit_component").hide();
  $(".left_box").removeClass("col-md-12");
  $(".left_box").addClass("col-md-4");
  $(".left_box .details_component").show();
  $(".right_box").show();
}

const signatureConsole = {
  show_edit_form,
  cancel_edit_form
};

export default signatureConsole;
