function toggle_roll_call_name(obj, kid_id, other_kid_id){
  let room_id = $(obj).data("room");
  let kid_ids_field = $("#roll_call_kids_"+room_id);
  let selected_kids = kid_ids_field.val();
  let other_kids_field = $("#other_room_kids_"+room_id);
  let other_kids = other_kids_field.val();
  if($(obj).is(":checked")){
    $(".rollcall_cards_"+room_id+" #info_span_"+kid_id).css("text-decoration", "line-through");
    kid_ids_field.val(selected_kids+kid_id+", ");
    if (!!other_kid_id) {
      other_kids_field.val(other_kids+other_kid_id+", ");
    }
  } else {
    $(".rollcall_cards_"+room_id+" #info_span_"+kid_id).css("text-decoration", "none");
    kid_ids_field.val(selected_kids.replaceAll(kid_id+", ", ""));
    if (!!other_kid_id) {
      other_kids_field.val(other_kids.replaceAll(other_kid_id+", ", ""));
    }
  }
}

function initRollcall(obj, room_id) {
  if($(obj).hasClass("rollcallInitiated")) {
    $(obj).removeClass("rollcallInitiated")
    cancelRollcall(room_id)
  } else {
    $(obj).addClass("rollcallInitiated")
    $('.cards_column_'+room_id).height($('.cards_column_'+room_id).height() - 50);
    $('.personable_cards_'+room_id).hide();
    $('.rollcall_cards_'+room_id).css("display", "block");
    $('#other_room_kids_heading_'+room_id).css("display", "block");
    $('.complete_rollcall_btn_'+room_id).show();
  }
}

function cancelRollcall(room_id) {
  $("[data-dest-room='"+room_id+"']").find('.rollcallInitiated').removeClass("rollcallInitiated");
  $('.cards_column_'+room_id).height($('.cards_column_'+room_id).height() + 50);
  $('.complete_rollcall_btn_'+room_id).hide();
  $('.rollcall_cards_'+room_id).hide();
  $('#other_room_kids_heading_'+room_id).hide();
  $('.personable_cards_'+room_id).show();
}

function endRollcall(room_id) {
  console.log("endRollcall" + room_id);
  let selected_kids = $(".rollcall_kids_"+room_id+":checked").length
  if (selected_kids > 0){
    $('#completeRollcallModal_'+room_id).modal('show');
    $(".kid_div_error").find("div").remove();
  }else{
    $(".kid_div_error").find("div").remove();
    $(".kid_div_error").append("<div class='alert alert-danger'><button type='button' class='close' data-dismiss='alert' aria-hidden='true'>×</button><p>Please select children.</p></div>");
  }
}

function sign_pad_initializer(room_ids){
  room_ids.forEach(function(room_id){
    initSignPad(room_id)
  });
}

function initSignPad(room_id){
  let canvas = document.getElementById('room_'+room_id+'_signature_pad')
  canvas.height = 200;
  canvas.width = 382;
  window['room_'+room_id+'_signature'] = new SignaturePad(canvas);
  document.getElementById('room_'+room_id+'_signature_clear').addEventListener('click', function () {
    window['room_'+room_id+'_signature'].clear();
  });
}

function filter_btn_pressed(src, path, model='room') {
  addLoaderScreen();
  $(`#${model}_filter_form`).attr("action", path);
  $(`#${model}_filter_src`).val(src);
}

function print_btn_pressed(src, path, model='room') {
  $(`#${model}_filter_form`).attr("action", path);
  $(`#${model}_filter_src`).val(src);
}

function export_btn_pressed(src, path, model='room') {
  $(`#${model}_filter_form`).attr("action", path);
  $(`#${model}_filter_src`).val(src);
}

function addDatePicker(id) {
  $(id).datepicker({
    changeMonth: true,
    changeYear: true,
    endDate: new Date()
  });
}

function roomChangeViaDashboard(obj) {
  $("#"+$(obj).data("room-dom-id")).append('<div class="activity_indicator"></div>');
  roomChange({
    src_room: $(obj).data("src-room"),
    dest_room: $(obj).val(),
    kid_ids: $(obj).data("kid-ids"),
    hide_loader: true,
    room_dom_id: $(obj).data("room-dom-id")
  });
}

function roomChange(args) {
  if (args.src_room !== args.dest_room) {
    if(!args.hide_loader){
      addLoaderScreen();
    }
    $.post("/rooms/change_room", args);
  }
}

function addLoaderScreen(message){
  $('body').addClass('freeze');
  if(message === "uploading") {
    $('body').append('<div id="cover-spin"><div id="cover-spin-content"><div class="progress" style="width:300px"><div class="progress-bar progress-bar-striped active" style="width:100%">Loading...</div></div></div></div>');
  } else {
    $('body').append('<div id="cover-spin"><div id="cover-spin-content"><span>'+(message || "")+'</span></div></div></div>');
  }
}

function removeLoaderScreen(){
  $('body').removeClass('freeze');
  $('#cover-spin').remove();
}

function initRoomsMonitor(){
  $("[data-toggle='tooltip']").tooltip();
  $('.signature_pad_submit').on("click", function(){
    let room_id = $(this).data("room");
    let signature_pad = window['room_'+room_id+'_signature'];
    if (signature_pad.isEmpty()) {
      alert("Please put a signature before you complete the Face to Name check.");
      event.preventDefault();
    } else {
      $('.roll_call_signature_'+room_id).val(signature_pad.toDataURL());
    }
  })
  addDatePicker('#room_filter_date');
  // if($("#rooms_board .column").length){
  //   let new_width = $("#rooms_board .column").length * 320;
  //   $("#rooms_board").css("width", new_width);
  //   $(".room_page").css("width", new_width);
  // }
  $('#rooms-data-table').dataTable();
  $('.data-table-without-paging').each(function(){
    $(this).dataTable({
      "order": [],
      "paging": false
    });
  })

  $(".move_room_link").click(function(){
    var columnList
    var kidIds = []
    if($(this).data("src-room")) {
      columnList = ".cards_column_"+$(this).data("src-room")
    } else {
      columnList = ".unclassified_children"
    }
    $(columnList+" .sortableMultiDragSelected").each(function(){
      kidIds.push($(this).data("kid-id"))
    })
    roomChange({
      src_room: "<%= params[:room_id] %>",
      dest_room: $(this).data("dest-room"),
      kid_ids: kidIds
    })
  })
}

$(document).ready(function(){
  initRoomsMonitor();
  // initSortableJs();
});


const roomsFunctions = {
  toggle_roll_call_name,
  initRollcall,
  cancelRollcall,
  endRollcall,
  sign_pad_initializer,
  initSignPad,
  filter_btn_pressed,
  print_btn_pressed,
  export_btn_pressed,
  addDatePicker,
  roomChangeViaDashboard,
  roomChange,
  addLoaderScreen,
  removeLoaderScreen,
  initRoomsMonitor
};

export default roomsFunctions;