import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "doNotCharge" , "reservationsMandatory"]
  connect() {
    //console.log("Payer controller !")
  }
  resetReservationOverstay()
  {
    // doNotChargeTarget is a radio button. make the value as do_not_charge when reservationsMandatoryTarget is unchecked
    if(!this.reservationsMandatoryTarget.checked)
    {
      this.doNotChargeTarget.checked = true;
    }
  }
}
