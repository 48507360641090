$(document).ready(function() {
  $("#copy_creditcard_amount").on("keyup", function(){
    if(!!$(this).val() && (parseFloat($(this).val()) > 0)){
      $("#terminal_payment_btn").removeAttr("disabled");
    } else {
      $("#terminal_payment_btn").attr("disabled", "disabled");
    }
  });
  // $("#copy_creditcard_amount").on("keyup", function(){
  //   let currentAmount = parseFloat($(this).val());
  //   let totalAmount = parseFloat($("#total_amount_tag").data("total"));
  //   if(!!$(this).val() && (currentAmount > totalAmount)){
  //     jsWarningResponse("Entered amount was grater than total amount.");
  //   } else if(!!$(this).val() && (currentAmount < totalAmount)){
  //     jsWarningResponse("Entered amount was less than total amount.");
  //   } else {
  //     jsWarningResponse("");
  //   }
  // });
})
var beforeUnloadFunction = function (e) {
  var confirmationMessage = 'Payment is in progress. Are you sure you want to leave?';
  e.returnValue = confirmationMessage; 
  return confirmationMessage;
};

function callCreateTerminalPayment(obj, data) {
  window.addEventListener('beforeunload', beforeUnloadFunction);
  $(obj).attr("disabled", "disabled");
  $.post("/create_terminal_payment", {
    terminal_transaction: Object.assign(data, {
      amount: $("#copy_creditcard_amount").val()
    })
  })
}

function createChannelStream(uuid, amount){
  addLoaderScreen("Please swipe the customer’s credit card now");
  $("#cover-spin").addClass("with_content");
  $('#cover-spin-content').append("<h1 class='text-success'>$" + parseFloat(amount).toFixed(2) + "</h1><button name='button' style='display: none;' id='cancel_terminal_btn' type='button' class='btn btn-primary' onclick='callCancelTerminalPayment(\""+uuid+"\")'>Cancel</button>");
  App.cable.subscriptions.create({
    channel: 'SquareChannel',
    transaction_uuid: uuid
  }, {
    received: function(data) {
      if(data.mock_response){
        console.log("=== MOCK_RESPONSE ===")
        var response = JSON.stringify(data.mock_response, null, 2)
        console.log(response)
        alert(response)
        console.log("=== MOCK_RESPONSE ===")
      }
      let square_stream = "[data-square-stream='terminal-transaction-"+data.uuid+"']";
      switch((data.status || "").toLowerCase()) {
        case "created":
          $("#cancel_terminal_btn").show();
          break;
        case "canceled":
          window.location.href=window.location.href
          break;
        case "failed":
          $("#partial_payment_alias_button").removeAttr("disabled")
          jsErrorResponse("Payment via terminal "+data.status+"!");
          $(square_stream+" #copy_creditcard_amount").val("")
          $(square_stream+" #terminal_payment_btn").removeAttr("disabled");
          removeLoaderScreen();
          break;
        case "completed":
          $(square_stream+" #payment_splitter").hide();
          $(square_stream+" #copy_creditcard_amount").val(data.amount);
          $(square_stream+" #copy_last_four").val(data.last_4);
          $(square_stream+" #copy_card_brand_master_card"+data.card_brand).click();
          ["#copy_creditcard_amount", "#copy_last_four", "[name='invoice_payment[credit_card_type]']"].map(id => {
            $(square_stream+" "+id).attr("readonly", "readonly");
          })
          submitPaymentForm();
          // debounceSubmitPaymentForm();
          // removeLoaderScreen();
          break;
      }
    },
  });
}

var paymentFormSubmitted = false;

function submitPaymentForm() {
  if (paymentFormSubmitted) return;
  paymentFormSubmitted = true;
  window.removeEventListener('beforeunload', beforeUnloadFunction);
  var payment_form = $("input[type='submit'].pay").closest("form")
  if(!payment_form.length){
    payment_form = $(".pay").closest("form")
  }
  payment_form.submit();
}

function callCancelTerminalPayment(uuid) {
  $('#cover-spin button').text('Cancelling...');
  $('#cover-spin button').attr('disabled', 'disabled');
  window.removeEventListener('beforeunload', beforeUnloadFunction);
  $.post("/cancel_terminal_payment", {uuid: uuid});
}
function jsErrorResponse(errors){
  $('#js_notification_area').html("<div class='alert alert-danger'><button type='button' class='close' data-dismiss='alert'>×</button>"+errors+"</li></div>");
}
// function jsWarningResponse(warning){
//   $('#over_under_notification').text(warning);
// }
function closeRefundModal(){
  $('#refund_amount_modal form').attr('action', '#')
  $('#refund_amount_modal #js_notification_area').html('')
  $('#refund_amount_modal input[type="text"]').val('')
  $('#refund_amount_modal').modal('hide')
}
function validateRefundAmount(obj){
  var amount = parseFloat($(obj).val() || "")
  if(amount > 0) {
    $('#refund_amount_modal input[type="submit"]').removeAttr("disabled")
  } else {
    $('#refund_amount_modal input[type="submit"]').attr("disabled", "disabled")
  }
}

const squareFunctions = {
  createChannelStream,
  callCreateTerminalPayment,
  callCancelTerminalPayment,
  closeRefundModal,
  validateRefundAmount,
  submitPaymentForm,
  jsErrorResponse,
  closeRefundModal
};

export default squareFunctions;

