import consumer from "./consumer"


consumer.subscriptions.create({channel: "SquareChannel"}, {
  connected() {
    // SquareChannel Called when the subscription is ready for use on the server
  },

  disconnected() {
    // SquareChannel Called when the subscription has been terminated by the server
  },

  received(data) {
    // SquareChannel Called when there's incoming data on the websocket for this channel
  }
});