import Webcam from './webcam.min.js';

export function take_snapshot(obj_id){
    Webcam.snap(function(data_uri) {
        let id = $('[id*="_image"]');

        if (id.length) {
            id.val(data_uri);
        }
        document.getElementById('results_'+obj_id).innerHTML = '<img style="width: 80px;"src="' + data_uri + '"/>';
        $(".save_capture_edit"+obj_id).prop('style', 'display: block;');
    });
}

export function attach_camera(obj_id) {
    Webcam.attach('.my_camera_'+obj_id);
    $(".attach_camera_"+obj_id).hide();
    $(".take_snapshot_"+obj_id).prop('style', 'display: block;');
    $(".save_capture_"+obj_id).prop('style', 'float: right;display: block;');
}


    $(document).ready(function () {
    if ($("#my_camera").length) {
        Webcam.set({
            width: 100,
            height: 100,
            image_format: 'jpg',
            jpg_quality: 90,
            force_flash: false
        });        
    }
});